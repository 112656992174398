<template>
  <div class="bg-gray-100">
    <main class="relative">
      <!--Coming Soon Banner-->
      <div class="flex items-center gap-x-6 bg-red-700 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
        <p class="text-sm leading-6 text-white">
          <a href="https://maps.app.goo.gl/3pTGsXoWjCtne2tC7" target="_blank">
            <strong class="font-semibold">Now Open!</strong><svg
              viewBox="0 0 2 2"
              class="mx-2 inline h-0.5 w-0.5 fill-current"
              aria-hidden="true"
            ><circle
              cx="1"
              cy="1"
              r="1"
            /></svg>In Rome, GA on Martha Berry Hwy&nbsp;<span aria-hidden="true">&rarr;</span>
          </a>
        </p>
        <div class="flex flex-1 justify-end">
          <button
            type="button"
            class="-m-3 p-3 focus-visible:outline-offset-[-4px]"
          >
            <span class="sr-only">Dismiss</span>
            <XMarkIcon
              class="h-5 w-5 text-white"
              aria-hidden="true"
            />
          </button>
        </div>
      </div>

      <!--Main Section-->
      <div class="relative bg-white">
        <div class="mx-auto max-w-7xl lg:grid lg:grid-cols-12 lg:gap-x-8 lg:px-8">
          <div class="px-6 pb-24 pt-10 sm:pb-32 lg:col-span-7 lg:px-0 lg:pb-56 lg:pt-48 xl:col-span-6">
            <div class="mx-auto max-w-2xl lg:mx-0">
              <img
                class="h-32"
                src="../../../public/fulllogo_transparent_nobuffer.webp"
                alt="Company Logo"
              >
              <h1 class="mt-24 text-4xl font-bold tracking-tight text-gray-900 sm:mt-10 sm:text-6xl">
                Band Instrument Repair Academy
              </h1>
              <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl pt-10">
                Our Mission
              </h2>
              <p class="mt-6 text-lg leading-8 text-gray-600">
                Band Instrument Repair Academy’s mission is to cultivate the next generation of skilled band instrument repair technicians, elevate the standards of instrument repair services in the industry by providing high-quality training, and serve the musicians in our community through a repair shop for the public.
              </p>
              <div class="mt-10 flex items-center gap-x-6">
                <a
                  href="/contact"
                  class="rounded-md bg-red-700 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500"
                >Contact Us</a>
                <a
                  href="/about"
                  class="text-sm font-semibold leading-6 text-gray-900"
                >Learn more <span aria-hidden="true">→</span></a>
              </div>
            </div>
          </div>
          <div class="relative lg:col-span-5 lg:-mr-8 xl:absolute xl:inset-0 xl:left-1/2 xl:mr-0">
            <img
              class="aspect-[3/2] w-full bg-gray-50 object-cover lg:absolute lg:inset-0 lg:aspect-auto lg:h-full"
              src="https://images.unsplash.com/photo-1713946848256-67dc4c74df5c?q=80&w=3087&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt=""
            >
          </div>
        </div>
      </div>

      <div class="bg-gray-100 py-16 sm:py-24">
        <div class="mx-auto max-w-7xl px-6 lg:px-8">
          <!--                    <div class="mx-auto max-w-2xl lg:mx-0">-->
          <!--                        <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Our Mission</h2>-->
          <!--                        <p class="mt-6 text-lg leading-8 text-gray-600">Band Instrument Repair Academy’s mission is to cultivate the next generation of skilled band instrument repair technicians, elevate the standards of instrument repair services in the industry by providing high-quality training, and serve the musicians in our community through a repair shop for the public.</p>-->
          <!--                    </div>-->
          <div class="mx-auto max-w-2xl lg:mx-0">
            <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Services
            </h2>
            <!--                        <p class="mt-6 text-lg leading-8 text-gray-600">Band Instrument Repair Academy’s mission is to cultivate the next generation of skilled band instrument repair technicians, elevate the standards of instrument repair services in the industry by providing high-quality training, and serve the musicians in our community through a repair shop for the public.</p>-->
          </div>
          <dl class="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 text-base leading-7 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            <div
              v-for="feature in features"
              :key="feature.name"
            >
              <dt class="font-semibold text-gray-900">
                {{ feature.name }}
              </dt>
              <dd class="mt-1 text-gray-600">
                {{ feature.description }}
              </dd>
            </div>
          </dl>
        </div>
      </div>

      <!--Cookie Banner-->
      <!--            <div class="fixed inset-x-0 bottom-0 flex flex-col justify-between gap-x-8 gap-y-4 bg-white p-6 ring-1 ring-gray-900/10 md:flex-row md:items-center lg:px-8">-->
      <!--                <p class="max-w-4xl text-sm leading-6 text-gray-900">This website uses cookies to supplement a balanced diet and provide a much deserved reward to the senses after consuming bland but nutritious meals. Accepting our cookies is optional but recommended, as they are delicious. See our <a href="#" class="font-semibold text-red-600">cookie policy</a>.</p>-->
      <!--                <div class="flex flex-none items-center gap-x-5">-->
      <!--                    <button type="button" class="rounded-md bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900">Accept all</button>-->
      <!--                    <button type="button" class="text-sm font-semibold leading-6 text-gray-900">Reject all</button>-->
      <!--                </div>-->
      <!--            </div>-->
    </main>
  </div>
</template>

<script setup>
import GuestLayout from "@/Layouts/GuestLayout.vue";

defineOptions({ layout: GuestLayout })

const features = [
    {
        name: 'In-House Training',
        description:
            'The Academy offers comprehensive, hands-on education in a supportive, in-house learning environment. Students receive expert instruction in repairing and maintaining woodwind and brasswind instruments. The curriculum emphasizes practical experience, ensuring graduates are well-prepared for successful careers in the music industry.',
    },
    {
        name: 'Virtual Training',
        description:
            'With the support of an approved affiliate repair shop, The Virtual Academy offers the same hands-on education to students located outside of the Southeast. Students will attend classes virtually while having their work monitored and inspected by technicians at the affiliate repair shop.',
    },
    {
        name: 'Adult Education',
        description:
            'The Adult Education Program is a two-year course in professional band instrument repair techniques, utilizing weekend shop time for hands-on learning. Participants gain practical experience in a supportive environment, honing their skills under expert guidance. This schedule accommodates working adults, ensuring they can advance their repair skills without disrupting their weekday commitments.',
    },
    {
        name: 'Instrument Repair',
        description:
            'The Band Instrument Repair Academy doubles as a repair shop, providing high-quality repair services for a wide range of band instruments. With a focus on precision and expertise, the Academy ensures that each instrument is meticulously repaired to meet industry standards.',
    },
    {
        name: 'Affiliate Repair Shop',
        description:
            'Affiliate Repair shops are critical to the Virtual Academy’s success, providing hands-on experience to students. This partnership allows learners to apply theoretical knowledge in a real-world setting under the direct supervision of experienced repair professionals, bridging the gap between virtual instruction and practical skills. The shop’s expertise and resources enhance the overall educational experience.',
    },
    {
        name: 'Clinician Services',
        description:
            'Clinician services in professional band instrument repair techniques offer expert guidance and training for individuals and groups. These services include workshops, seminars, and personalized sessions led by experienced professionals. Participants gain advanced skills and knowledge, enhancing their proficiency in instrument repair and ensuring high-quality maintenance on repairs they perform.',
    },
]

</script>
